import * as React from 'react';
import {CardContent} from '@material-ui/core';
import {FilterLiveSearch} from 'react-admin';

import CardComp from "../_common/CardComp";
import {FilterDataAside} from "../_common/CompReact";
// import {FilterList, FilterListItem} from 'react-admin';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//import TrueFalseFilters from "../_common/TrueFalseFilters";
import {deletedTrueFalseAll, titleMrMs} from "../utils/choices";
import DynamicFiltersChoices from "../_common/DynamicFiltersChoices";
// import {commonYesNo} from "../utils/choices";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch label={'Search'}/>
                <DynamicFiltersChoices
                    label={'Deleted'}
                    reference={deletedTrueFalseAll}
                    fieldsSearch={'is_deleted'}
                    fieldsNameForFilter={'name'}
                />
                <FilterDataAside fields={'created_at'} name={'created'}/>
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                <DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />
                {/*<TrueFalseFilters field={'paid'} label={'paid'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
                {/*
                <DynamicFiltersChoices
                    reference={commonYesNo}
                    fieldsSearch={'show'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<DynamicFiltersChoices
                    reference={stateQuestion}
                    fieldsSearch={'state'}
                    fieldsNameForFilter={'name'}
                />*/}
            </CardContent>
        </CardComp>
    )
};

export default Aside;
