//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    SimpleList,
    TextField,
    useLocale,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';
import PassengerListAside from "./PassengerListAside";

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {PassengerTopFilters} from './PassengerTopFilters'
import {PassengerColumnsForList} from "./PassengerColumnsForList";
import {exporterPassenger} from "../utils/exporterFile";
//import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";

/*const useImageFieldStyles = makeStyles(theme => ({
    image: {
        width: 50,
        height: 50,
    }
}));
import {makeStyles} from '@material-ui/core/styles';*/
/*
const PostShow = () => (
    <SimpleShowLayout>
        <TextField source={`programName`} label={'program name'}/>
        <TextField source={`account_last_name`} label={'account last name'}/>
        <TextField source={`account_first_name`} label={'account first name'}/>
        <TextField source={`account_number`} label={'account number'}/>
        <TextField source={`password`} label={'password'}/>
        <TextField source={`dob`} label={'dob'}/>
        <TextField source={`phone`} label={'phone'}/>
        <TextField source={`cvc`} label={'cvc'}/>
        <TextField source={`exp`} label={'exp'}/>
        <EmailField source={`email`} label={'email'}/>
        <TextField source={`email_pasw`} label={'email password'}/>
        <EditCommentFieldFromList source={`notes_program`} label={'notes program'} component={'text'} long={'200px'}/>
    </SimpleShowLayout>
);
*/

export const PassengerList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_deleted
            ? 'lightcoral'
            : '#fefefe',
        opacity: record?.is_deleted ? 0.5 : 1,
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: PassengerColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={' '}

            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={PassengerColumnsForList(resource, locale, classes)}
                permissions={permissions}
                asideFilter={true}
                // filter={false}
                //exportCsv={true}
                exporterFile={exporterPassenger}
            />}
            filters={PassengerTopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<PassengerListAside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}