import * as React from 'react';
import {
    //NumberField,
    TabbedForm,
    FormTab,
    TextInput,
    // useLocale,
    //NumberInput,
    //BooleanInput,
    SelectInput, AutocompleteInput, ReferenceInput, NumberInput, BooleanInput
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, DateInputComp, GetFieldById, TextFieldButton} from "../_common/CompReact";
import {titleMrMs} from "../utils/choices";
import {validateFirstName, validateReq} from "../utils/validateTable";
import useMyStyles from "../utils/useMyStyles";
import HistoryListForFilter from "../_common/HistoryListForFilter";
import Alert from "@material-ui/lab/Alert";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";

export const PassengerEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const classes = useMyStyles();
    const {record, customerId} = props;
    const {id, is_deleted} = record;
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Name</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="title"
                                source2="f_name"
                                source3="l_name"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <Box p="1em" fullWidth>

                        {is_deleted &&
                            <Box fullWidth>
                                <Alert severity="error" style={{color: "red", textAlign: "center"}}>
                                    <h3 style={{margin: 0}}>Attention !!! This entry is marked as deleted. If you want,
                                        you can restore it.</h3>
                                    <BooleanInput
                                        style={{marginBottom: -32}}
                                        source="is_deleted" label={'Is deleted'}
                                        defaultValue={false}
                                    />
                                </Alert>
                            </Box>
                        }
                        <Box display="flex" alignItems={"center"}>
                            {customerId
                                ?
                                <Box flex={1} mr={'2em'} mb={'1em'}>
                                    <div className={classes.cost_show_field}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Customer'}</span>
                                        </div>
                                        <div>
                                            <GetFieldById id={customerId} resource={'customer'} field={'name'}/>
                                        </div>
                                    </div>
                                    <Box hidden><NumberInput source={'customer_id'} defaultValue={customerId}/></Box>
                                    {/*<Typography variant="h6" gutterBottom>
                                        <GetFieldById id={customerId} resource={'customer'} field={'name'}/>
                                        <Box hidden><NumberInput source={'customer_id'} defaultValue={customerId}/></Box>
                                    </Typography>*/}
                                </Box>
                                :
                                <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                    {/*<Typography variant="h5" gutterBottom>ID: </Typography>*/}
                                    {/*<NumberField source="id" variant="h5"/>*/}
                                    <ReferenceInput source="customer_id" reference="customer">
                                        <AutocompleteInput resettable optionText="name" fullWidth/>
                                    </ReferenceInput>
                                </Box>
                            }
                            <Box flex={1} mr={'2em'}>
                                <BooleanInput source="is_main" label={'Is main'} defaultValue={false}/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                            </Box>
                            <Box flex={1}>
                                <DateInputComp source={"b_day"} label={"Birthday"} fullWidth/>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <SelectInput source="title" choices={titleMrMs} validate={validateReq} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="f_name" label={'First Name'} validate={validateFirstName} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="m_name" label={'Middle Name'} fullWidth/>
                            </Box>
                            <Box flex={1}>
                                <TextInput source="l_name" label={'Last Name'} validate={validateFirstName} fullWidth/>
                            </Box>
                        </Box>

                    </Box>
                </FormTab>
                {/*<FormTab label="History">
                    <HistoryListForFilter {...props} table={'passenger'} fullWidth/>
                </FormTab>*/}
                {id && !customerId &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'passenger'} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default PassengerEditCreate
